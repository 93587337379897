import React, { useState, useEffect, useContext } from 'react';
import unsplash from "../utils/unsplashConfig";
import { ImgContext } from '../utils/ImgContext';

const SelectBgImage = ({ config }) => {
  // const { title, author, font, icon, customIcon, platform, bgColor } = config;

  // const [image, setImage] = useState({})

  const [source, setSource] = useState('Unplash')

  const [imageList, setImageList] = useState([]);
  const [searchText, setSearchText] = useState('dev');

  const { unsplashImage, setUnsplashImage } = useContext(ImgContext);

  const searchImages = () => {

    unsplash.search
        .getPhotos({
          query: searchText,
          page: 1,
          per_page: 30,
          // orientation:'portrait'


        })
        .then(response => {
          // console.log(response.response.results);
          setImageList(response.response.results)
        });
  }

  useEffect(() => {

    unsplash.search
        .getPhotos({
          query: 'setup',
          page: 1,
          per_page: 30

        })
        .then(response => {
          // console.log(response.response.results);
          setImageList(response.response.results)
        });
  }, [])

  const selectImage = (image) => {
    setUnsplashImage({
      url: image.urls.regular,
      name: image.user.name,
      avatar: image.user.profile_image.small,
      profile: `${image.user.links.html}?utm_source=https://coverview.vercel.app&utm_medium=referral`,
      downloadLink: image.links.download_location

    })
  }

  const selectLocalImage = (image) => {
    setUnsplashImage({
      url: URL.createObjectURL(image.target.files[0]),
      name: 'test',
      avatar: '',
      profile: '',
      downloadLink: ''
    })
  }

  const uploadLocalImage = () => {
    const iconFile = document.getElementById("uploadLocalImage")
    iconFile.click()
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchImages(searchText);

  }


  return (
      <div className=" bg-white rounded">
        <div className={` overflow-y-hidden flex flex-col rounded`}>

          <div className="flex flex-row items-center bg-white  justify-center  ">
            <div className="w-full">
                  <div className="flex flex-col p-2 flex-wrap  bg-white items-center justify-center overflow-x-hidden">

                    <div className="flex flex-col w-full ">
                      {/*<div className="font-Namdhinggo font-semibold text-base text-gray-700">Click image to select</div>*/}
                      <span className="font-Namdhinggo font-semibold text-lg pb-1">Source</span>

                      <div className="flex items-center">
                        <div className="flex flex-col w-1/2 mr-4">
                          <select
                              value={source}
                              onChange={(e) => setSource(e.target.value)}
                              className="focus:outline-none text-gray-700 text-base p-2 rounded border ">
                            <option>Unplash</option>
                            <option>Upload</option>
                          </select>
                        </div>

                        {source === "Unplash" ?
                        <form onSubmit={(e) => handleSearchSubmit(e)} className=" ml-auto mr-2 w-1/2 flex bg-gray-50 rounded border">
                          <input type="text"
                                 value={searchText}
                                 placeholder="Search image"
                                 className="focus:outline-none w-full text-base text-gray-700 bg-gray-50  p-1 px-4  rounded border border-gray-50"
                                 onChange={(e) => setSearchText(e.target.value)}
                          />

                          <button type="submit" onClick={() => searchImages(searchText)}>
                            <svg className="w-9 h-9 ml-auto p-2 bg-gray-700 hover:bg-gray-800 text-white rounded" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                          </button>
                        </form>: <div className="flex flex-col w-1/2 ml-2 p-2 border bg-gray-300 rounded">
                              <input id="uploadLocalImage" type="file"
                                     style={{display: "none"}}
                                     onChange={(e) => selectLocalImage(e)}
                              />
                              <button onClick={uploadLocalImage}>
                                <div className="text-gray-700 text-sm font-medium hover:text-blue-500">Upload Image</div>
                              </button>
                            </div>
                        }
                      </div>
                    </div>


                    {/*TODO: height*/}
                    <div className="overflow-y-scroll overflow-x-hidden justify-center flex flex-wrap mt-2" style={{height: '28rem'}}>
                      {
                        imageList.map(image => {
                          return <img src={image.urls.regular}
                                      key={image.id}
                                      alt={image.alt_description}
                                      className="rounded m-2 cursor-pointer object-cover w-4/5 h-40"
                                      onClick={() => selectImage(image)
                                      }
                          />
                        })
                      }
                    </div>
                  </div>
            </div>
          </div>


        </div>

      </div>
  );
}

export default SelectBgImage;
