import React from "react";
import Select from 'react-select';
import { Tab } from '@headlessui/react'
import { Slider } from "react-semantic-ui-range";

import CoverImage from "./CoverImage";
import ComponentToImg from "./ComponentToImg";
import { ImgProvider } from '../utils/ImgContext'
import Header from "./Header";
import SelectBgImage from "./SelectBgImage";
import RandomTheme from './RandomTheme';

import theme1 from '../assets/images/theme1.webp'
import theme2 from '../assets/images/theme2.webp'
import theme3 from '../assets/images/theme3.webp'
import theme4 from '../assets/images/theme4.webp'
import theme5 from '../assets/images/theme5.webp'
import theme6 from '../assets/images/theme6.webp'
import theme7 from '../assets/images/theme7.webp'


// 加个空白的，顺便补上没有icon的
const defaultIcon = {'label': 'react', 'value': 'react'}
const defaultPlatform = {'label': 'Fixed 16:9', 'value': 'auto169'}
const defaultIconSource = 'DevIcon'
const defaultImageSource = 'Unplash'

const devIconsUrl = "https://raw.githubusercontent.com/devicons/devicon/master/devicon.json"

const platformOptions = [
  {value: "auto169", label: "Fixed 16:9"},
  // {value: "auto916", label: "fixed 9:16"},
  {value: "auto43", label: "Fixed 4:3"},
  // {value: "auto34", label: "fixed 3:4"},
  // {value: "auto11", label: "fixed 1:1"},
  {value: "medium", label: "medium 900 * 450"},
  {value: "wix", label: "wix 940 * 726"},
  {value: "hashnode", label: "hashnode 800 * 420"},
  {value: "tumblr_header", label: "tumblr header 3000 * 1055"},
  // {value: "tumblr_post", label: "tumblr post 500 * 750"},
  {value: "notion_desktop", label: "notion desktop 1500 * 600"},
  // {value: "notion_mobile", label: "notion mobile 1170 * 230"},
  {value: "tiktok_hor", label: "tiktok desktop 1920 * 1080"},
  // {value: "tiktok_ver", label: "tiktok mobile 1080 * 1920"},
  {value: "youtube_hor", label: "youtube desktop 1280 * 720"},
  // {value: "youtube_ver", label: "youtube mobile 720 * 1080"},
  {value: "facebook", label: "facebook home 851 * 315"},
  {value: "wechat", label: "wechat official 900 * 383"}
]

// TODO：控制字体大小
const defaultSettings = {
  platform: defaultPlatform,
  title: 'Easycover: A Tool make it super easier to create cover',
  author: 'drwtech',
  titleSize: "48",
  authorSize: "20",
  font: 'font-Anek',
  fontColor: '',
  bgColor: '#949ee5',
  theme: 'background',
  customIcon: '',
  pattern: '',
  download: 'PNG',
  iconSource: defaultIconSource,
  icon: defaultIcon,
  devIconOptions: [defaultIcon],
  imageSource: defaultImageSource
};


class Editor extends React.Component {

  state = defaultSettings;

  titleSlider = {
    min: 12,
    max: 60,
    step: 1,
    onChange: value => {
      this.setState({titleSize: value})
    }
  };

  authorSlider = {
    min: 12,
    max: 60,
    step: 1,
    onChange: value => {
      this.setState({authorSize: value})
    }
  }

  componentDidMount() {
    fetch(devIconsUrl).then(r => r.json()).then(data => {
      this.setState({devIconOptions: data.map(item => ({'value': item.name, 'label': item.name}))})
    })
  }

  handleReset = () => {
    this.setState(defaultSettings);
  };

  // TODO:
  getRandomTheme = (theme, Pattern) => {
    this.setState({bgColor: theme.bgColor, borderColor: theme.bdColor, pattern: Pattern});
  }

  formatOptionLabel = ({value, label}) => (
      <div className="flex">
        <span className="mr-4">{label}</span>
        <div className="ml-auto">
          <i className={`devicon-${value}-plain dev-icon text-xl`}></i>
        </div>
      </div>
  );

  uploadLocalIcon = () => {
    const iconFile = document.getElementById("uploadLocalIcon")
    iconFile.click()
  }

  render() {
    return (
        <div>
          <Header/>

          <ImgProvider>
            <div className="flex md:flex-row flex-col bg-gray-50 ">
              <div className="bg-white flex flex-col h-100 md:w-3/12">
                <Tab.Group>
                  <div className="flex md:flex-row flex-col">

                    <Tab.List className=" bg-white md:p-0 p-2 flex flex-row md:flex-col">
                      <Tab className="flex  items-center font-semibold focus:outline-none ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             className="text-gray- bg-white rounded-xl w-12 m-2 h-12 p-2 rounded border" width="24"
                             height="24" viewBox="0 0 24 24">
                          <path
                              d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path>
                        </svg>
                      </Tab>

                      <Tab className="flex items-center font-semibold text-lg focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                             className="text-gray- bg-white w-12 m-2 h-12 p-2 rounded border" width="24" height="24"
                             stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                             viewBox="0 0 24 24">
                          <rect height="18" rx="2" ry="2" width="18" x="3" y="3"/>
                          <path d="M3 9h18M9 21V9"/>
                        </svg>
                        {/*<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className=" text-gray-800 bg-white rounded-xl w-12 h-12 p-2 m-2 rounded border" width="24" height="24" viewBox="0 0 24 24" ><path d="M11.024 11.536 10 10l-2 3h9l-3.5-5z"></path><circle cx="9.503" cy="7.497" r="1.503"></circle><path d="M19 2H6c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H6.012C5.55 19.988 5 19.806 5 19s.55-.988 1.012-1H21V4c0-1.103-.897-2-2-2zm0 14H5V5c0-.806.55-.988 1-1h13v12z"></path></svg>*/}
                      </Tab>
                    </Tab.List>

                    <Tab.Panels className="bg-white border-l w-full py-4 ">
                      <Tab.Panel>
                        <div className="flex items-center">
                          <div className="flex flex-col m-2 w-full">
                            <span className="font-Namdhinggo font-semibold text-lg pb-1">Platform</span>

                            {/*效仿devIcon 搞一个开源项目，加上他们的icon*/}
                            <Select
                                options={platformOptions}
                                isSearchable
                                value={this.state.platform}
                                onChange={(p) => this.setState({platform: p})}
                                className="focus:outline-none text-gray-700 text-base rounded mb-1">
                            </Select>
                          </div>
                        </div>

                        <div className="m-2 flex flex-col">
                          <span className="font-Namdhinggo font-semibold text-lg pb-1">Title</span>
                          <textarea
                              type="text"
                              value={this.state.title}
                              placeholder="Enter title here"
                              className="focus:outline-none border text-gray-700 text-base rounded p-2 h-24"
                              onChange={(e) => this.setState({title: e.target.value})}
                          />
                        </div>

                        <div className="flex items-center mb-1">
                          <div className="flex flex-col w-5/6">
                            <Slider value={this.state.titleSize} color="blue" settings={this.titleSlider}/>
                          </div>
                          <div className="flex flex-col w-1/6">
                            <input
                                type="text"
                                value={this.state.titleSize}
                                className="bg-gray-200 focus:outline-none border text-gray-600 text-base text-center rounded mr-2"
                                onChange={(e) => this.setState({titleSize: e.target.value})}
                            ></input>
                          </div>
                        </div>

                        <div className="flex flex-col m-2 ">
                          <span className="font-Namdhinggo font-semibold text-lg pb-1">Author</span>
                          <input
                              type="text"
                              value={this.state.author}
                              placeholder="Author"
                              className="focus:outline-none border text-gray-700 text-base rounded bg-white p-2"
                              onChange={(e) => this.setState({author: e.target.value})}
                          ></input>
                        </div>

                        <div className="flex items-center mb-1">
                          <div className="flex flex-col w-5/6">
                            <Slider value={this.state.authorSize} color="blue" settings={this.authorSlider}/>
                          </div>
                          <div className="flex flex-col w-1/6">
                            <input
                                type="text"
                                value={this.state.authorSize}
                                className="bg-gray-200 focus:outline-none border text-gray-600 text-base text-center rounded mr-2"
                                onChange={(e) => this.setState({authorSize: e.target.value})}
                            ></input>
                          </div>
                        </div>

                        <div className="flex flex-col m-2 ">
                          <span className="font-Namdhinggo font-semibold text-lg pb-1">Icon</span>
                          <div className="flex items-center">
                            <div className="flex flex-col w-2/5">
                              <select
                                  value={this.state.iconSource}
                                  onChange={(e) => this.setState({iconSource: e.target.value})}
                                  className="focus:outline-none text-gray-700 text-base p-2 rounded border">
                                <option>DevIcon</option>
                                <option>Custom</option>
                              </select>
                            </div>
                            {this.state.iconSource === defaultIconSource ?
                                <div className="flex flex-col w-3/5 ml-2">
                                  <Select value={this.state.icon}
                                          onChange={(selectedOption) => this.setState({icon: selectedOption})}
                                          options={this.state.devIconOptions}
                                          formatOptionLabel={this.formatOptionLabel}
                                          className="outline-none focus:outline-none text-base text-gray-700"
                                  />
                                </div>
                                :
                                <div className="flex flex-col w-3/5 ml-2 p-2 border bg-gray-300 rounded">
                                  <input id="uploadLocalIcon" type="file"
                                         style={{display: "none"}}
                                         onChange={(e) => this.setState({'customIcon': URL.createObjectURL(e.target.files[0])})}
                                  />
                                  <button onClick={this.uploadLocalIcon}>
                                    <div className="text-gray-700 text-sm font-medium hover:text-blue-500">Upload Icon
                                    </div>
                                  </button>
                                </div>
                            }
                          </div>
                        </div>


                        <div className="flex items-center">
                          <div className="flex flex-col m-2 w-2/5">
                            <span className="font-Namdhinggo font-semibold text-lg pb-1">Font</span>
                            <select
                                value={this.state.font}
                                onChange={(e) => this.setState({font: e.target.value})}
                                className="focus:outline-none text-gray-700 text-base p-2 rounded border">
                              <option>font-serif</option>
                              <option>font-sans</option>
                              <option>font-mono</option>
                              <option>font-Inter</option>
                              <option>font-Poppins</option>
                              <option>font-Anek</option>
                            </select>
                          </div>
                          {/*TODO：背景主题不应该有颜色，应该控制字体颜色，而且换成其他主题的时候还要多一行控制背景颜色*/}
                          <div className="flex flex-col w-3/5">
                            <span className="font-Namdhinggo font-semibold text-lg pb-1">Color</span>
                            <div className="border rounded flex items-center p-2">

                              <span className="text-sm text-gray-700  mx-2">{this.state.bgColor}</span>
                              <input type="color" value={this.state.bgColor}
                                     onChange={(e) => this.setState({bgColor: e.target.value})}
                                     className="h-5 w-12 ml-auto mr-1 rounded"
                              />
                            </div>
                          </div>
                        </div>


                        {/*<div className="flex items-center">*/}
                        {/*	 /!*TODO：pattern*!/*/}
                        {/*	 <div className="flex flex-col m-2 w-1/2">*/}
                        {/*		<span className="font-medium pb-1">Pattern</span>*/}
                        {/*		<select*/}
                        {/*			onChange={(e) => this.setState({ pattern: e.target.value })}*/}
                        {/*			className="focus:outline-none border text-xl p-2 rounded"*/}
                        {/*			value={this.state.pattern}>*/}

                        {/*			<option>none</option>*/}
                        {/*			<option>graph-paper</option>*/}
                        {/*			<option>jigsaw</option>*/}
                        {/*			<option>hideout</option>*/}
                        {/*			<option>dots</option>*/}
                        {/*			<option>falling-triangles</option>*/}
                        {/*			<option>circuit-board</option>*/}
                        {/*			<option>temple</option>*/}
                        {/*			<option>anchors</option>*/}
                        {/*			<option>brickwall</option>*/}
                        {/*			<option>overlapping-circles</option>*/}
                        {/*			<option>wiggle</option>*/}
                        {/*			<option>tic-tac-toe</option>*/}
                        {/*			<option>leaf</option>*/}
                        {/*			<option>bubbles</option>*/}
                        {/*			<option>squares</option>*/}
                        {/*			<option>explorer</option>*/}
                        {/*			<option>jupiter</option>*/}
                        {/*			<option>sun</option>*/}
                        {/*		</select>*/}
                        {/*	</div>*/}

                        {/*	<div className="flex flex-col m-2 w-full">*/}
                        {/*		<span className="font-medium pb-1">Platform</span>*/}

                        {/*		<select*/}
                        {/*			onChange={(e) => this.setState({ platform: e.target.value })}*/}
                        {/*			value={this.state.platform}*/}
                        {/*			className="focus:outline-none text-gray-700 text-xl p-2 rounded border">*/}
                        {/*			<option>hashnode</option>*/}
                        {/*			<option>dev</option>*/}
                        {/*			<option>wechat</option>*/}
                        {/*		</select>*/}
                        {/*	</div>*/}
                        {/*</div>*/}

                        <button
                            className="flex items-center bg-gray-700 text-white rounded-lg mt-6 text-lg font-semibold p-1 px-4 mx-auto border"
                            onClick={this.handleReset}>
                          <span>Reset All</span>
                        </button>

                      </Tab.Panel>


                      <Tab.Panel className="h-99 w-full flex flex-col justify-center">

                        <div className="flex items-center border rounded-xl border-gray-50 px-4">
                          <h2 className="text-lg pl-2 font-inter font-semibold">Themes</h2>
                          <div className="ml-auto mr-1 p-2">
                            <RandomTheme onThemeChange={this.getRandomTheme}/>

                          </div>
                        </div>

                        <div className="p-4  flex flex-wrap  overflow-y-scroll ">

                          <img src={theme7} alt="basic theme"
                               onClick={(e) => this.setState({theme: "background"})}
                               className=" cursor-pointer border border-gray-100 hover:border-gray-200 hover:scale-105 duration-300 m-2"
                          />
                          <img src={theme1} alt="basic theme"
                               onClick={(e) => this.setState({theme: "basic"})}
                               className=" cursor-pointer border-gray-100 hover:scale-105 duration-300 hover:border-gray-200 border m-2 "
                          />
                          <img src={theme2} alt="basic theme"
                               onClick={(e) => this.setState({theme: "modern"})}
                               className="cursor-pointer border-gray-100 hover:scale-105 hover:border-gray-200 duration-300 border m-2 "
                          />
                          <img src={theme3} alt="basic theme"
                               onClick={(e) => this.setState({theme: "stylish"})}
                               className=" cursor-pointer border border-gray-100 hover:border-gray-200 hover:scale-105 duration-300 m-2"
                          />

                          <img src={theme5} alt="basic theme"
                               onClick={(e) => this.setState({theme: "outline"})}
                               className=" cursor-pointer border border-gray-100 hover:border-gray-200 hover:scale-105 duration-300 m-2"
                          />

                          <img src={theme4} alt="basic theme"
                               onClick={(e) => this.setState({theme: "preview"})}
                               className=" cursor-pointer border border-gray-100 hover:border-gray-200 hover:scale-105 duration-300 m-2"
                          />
                          <img src={theme6} alt="basic theme"
                               onClick={(e) => this.setState({theme: "mobile"})}
                               className=" cursor-pointer border border-gray-100 hover:border-gray-200 hover:scale-105 duration-300 m-2"
                          />
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </div>
                </Tab.Group>


                {/* <div className="mx-4 my-1">
						<h6>Download As</h6>
						<select onChange={(e) => this.setState({ download: e.target.value })}
							className="form-control input"
							value={this.state.download}>
							<option>PNG</option>
							<option>JPEG</option>
						</select>
					</div> */}

              </div>

              {/*TODO: 背景/底框颜色 */}
              <div className="flex m-6 flex-col md:w-6/12 items-center justify-center">
                <ComponentToImg downloadAs={this.state.download} platform={this.state.platform}>
                  <CoverImage {...this.state} />
                </ComponentToImg>
              </div>

              <div className="bg-white flex flex-col py-4 md:w-3/12">
                <div className="">
                  <SelectBgImage config={this.state}></SelectBgImage>
                </div>
              </div>
            </div>
          </ImgProvider>
        </div>
    );
  }
}

export default Editor;
