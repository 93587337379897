import React, { useEffect, useContext } from 'react';
import { ImgContext } from '../../utils/ImgContext';
import background from '../../assets/images/background.webp'

const BackgroundTheme = ({ config }) => {
    const { title, author, font, icon, customIcon, platform, titleSize, authorSize } = config;

    const { unsplashImage, setUnsplashImage } = useContext(ImgContext);

    useEffect(() => {
      setUnsplashImage({
        url: background,
      })
    }, [])

    return (
        <div className="bg-white rounded">
            <div className={` overflow-y-hidden flex flex-col rounded ${platform.value}`}>
                <div className="flex flex-row items-center bg-white justify-center  ">
                    <div className="w-full">

                        {unsplashImage ?
                            <div className='relative flex group'>
                                <div className="h-max w-full ">
                                    <img src={unsplashImage.url} className="object-cover h-full w-full" alt="preview" />
                                </div>


                                <div className=" backdrop-blur-sm h-full bg-gray-800/60 absolute top-0 right-0 left-0 ">
                                    <div className={`${font} px-10 pt-32  text-left rounded-xl h-full p-4 flex flex-col`}>
                                        <h1 className=" text-center font-bold text-white" style={{fontSize: titleSize + "px", lineHeight: 1}}>{title}</h1>
                                        <div className="flex flex-col items-center py-10  ">

                                            <h2 className="font-semibold text-left text-white" style={{fontSize: authorSize + "px", lineHeight: 1}}>{author}</h2>
                                            {
                                                customIcon ?
                                                    <div className="mt-2">
                                                        <img src={customIcon} alt="img" className="w-12 h-12 m-2 rounded-full bg-white border-2 border-white" />
                                                    </div>
                                                    :
                                                    <div className="mt-2 mr-2 items-center justify-center flex">
                                                        <i className={`devicon-${icon.value}-plain text-white dev-icon text-3xl`}></i>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            : <div></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackgroundTheme;
